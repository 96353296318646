import moment from 'moment';
import Button from 'components/DS/Button';
import { HiTrash } from 'react-icons/hi';
import { TbTrashOff } from 'react-icons/tb';
import { VscCheck, VscChromeClose } from 'react-icons/vsc';
import { meses } from 'utils/arrays/meses';
import './style.scss';

const TableBody = ({
  billing,
  setHasSendDate,
  setOpenDocumentsList,
  setShowHistoric,
  setTransitionLeave,
  setOpenMessageModal,
  openEditData,
  setOpenEditData,
  newData,
  setNewData,
  handleBillingDataChange,
  updateBillingData,
  getDocumentsList,
  getHistoric,
  openDeleteModalHandler,
  sendEmail
}) => {
  return (
    <div className="billing-body" key={billing.id}>
      <div>
        {openEditData.id === billing.id && openEditData.data === 'ano' ? (
          <div className="billing-body-content__edit-data">
            <input
              className="billing-body-content__input"
              type="text"
              value={newData}
              onChange={handleBillingDataChange}
            />
            <Button
              variant={!billing.data_envio ? 'save' : 'disabled'}
              size="small"
              isDisabled={!billing.data_envio ? false : true}
              onClick={() => updateBillingData(billing.id, 'ano', newData)}
            >
              <VscCheck className="billing-body-content__icon" size={14} />
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => {
                setNewData('');
                setOpenEditData({ id: 0, data: '' });
              }}
            >
              <VscChromeClose size={14} />
            </Button>
          </div>
        ) : (
          <div
            className="billing-body-content"
            onClick={() => setOpenEditData({ id: billing.id, data: 'ano' })}
          >
            <p>{billing.ano}</p>
          </div>
        )}
      </div>
      <div>
        {openEditData.id === billing.id && openEditData.data === 'mes' ? (
          <div className="billing-body-content__edit-data">
            <select
              className="billing-body-content__input"
              value={newData}
              onChange={handleBillingDataChange}
            >
              <option value="">Selecione</option>
              {meses.map((mes) => (
                <option value={mes}>{mes}</option>
              ))}
            </select>
            <Button
              variant={!billing.data_envio ? 'save' : 'disabled'}
              size="small"
              isDisabled={!billing.data_envio ? false : true}
              onClick={() => updateBillingData(billing.id, 'mes', newData)}
            >
              <VscCheck className="billing-body-content__icon" size={14} />
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => {
                setNewData('');
                setOpenEditData({ id: 0, data: '' });
              }}
            >
              <VscChromeClose size={14} />
            </Button>
          </div>
        ) : (
          <div
            className="billing-body-content"
            onClick={() => setOpenEditData({ id: billing.id, data: 'mes' })}
          >
            <p>{billing.mes}</p>
          </div>
        )}
      </div>
      <div>
        {openEditData.id === billing.id &&
        openEditData.data === 'valor_mensal' ? (
          <div className="billing-body-content__edit-data">
            <input
              className="billing-body-content__input"
              type="text"
              value={newData}
              onChange={handleBillingDataChange}
            />
            <Button
              variant={!billing.data_envio ? 'save' : 'disabled'}
              size="small"
              isDisabled={!billing.data_envio ? false : true}
              onClick={() =>
                updateBillingData(billing.id, 'valor_mensal', newData)
              }
            >
              <VscCheck className="billing-body-content__icon" size={14} />
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => {
                setNewData('');
                setOpenEditData({ id: 0, data: '' });
              }}
            >
              <VscChromeClose size={14} />
            </Button>
          </div>
        ) : (
          <div
            className="billing-body-content"
            onClick={() =>
              setOpenEditData({ id: billing.id, data: 'valor_mensal' })
            }
          >
            <p>{`R$ ${billing.valor_mensal}`}</p>
          </div>
        )}
      </div>
      <div>
        <Button
          variant="transparent"
          size="small"
          onClick={() => setOpenMessageModal(billing)}
        >
          Ver mensagem
        </Button>
      </div>
      <div>
        <Button
          variant="transparent"
          size="small"
          onClick={() => {
            getDocumentsList(billing.id);
            setHasSendDate(billing);
            setOpenDocumentsList(true);
            setTransitionLeave(false);
          }}
        >
          Ver documentos
        </Button>
      </div>
      <div>
        {!billing.data_envio ? (
          <div className="billing-body-buttons__send">
            <Button
              variant="save"
              size="small"
              onClick={() => sendEmail(billing.id)}
            >
              Enviar
            </Button>
          </div>
        ) : (
          <div className="billing-body-content">
            <p>{`Enviado dia: ${moment(billing.data_envio).format(
              'DD/MM/YYYY'
            )}`}</p>
          </div>
        )}
      </div>
      <div>
        <Button
          variant="transparent"
          size="small"
          onClick={() => {
            getHistoric(billing.id);
            setShowHistoric(true);
            setTransitionLeave(false);
          }}
        >
          Ver histórico
        </Button>
      </div>
      <div>
        {!billing.data_envio ? (
          <Button
            size="small"
            variant="remove"
            onClick={() => openDeleteModalHandler(billing.id)}
          >
            <HiTrash size={18} />
          </Button>
        ) : (
          <Button size="small" variant="transparent-disabled">
            <TbTrashOff size={18} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableBody;
