import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-datetime/css/react-datetime.css';
import { BiPlus } from 'react-icons/bi';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';
import { Radio } from '@mui/material';
import Button from 'components/DS/Button';
import { arrObjectMonth } from 'utils/arrays/meses';
import monthsObject from 'utils/objects/optionsMonth';
import ComissionamentoHistorico from './ComissionamentoHistorico';
import './style-comissionamento.scss';
import moment from 'moment';

const ComissionamentoInner = ({ title, data, getData }) => {
  // Estado do Array renderizado na tela
  const [comissionamentos, setComissionamentos] = useState([]);
  const [openTable, setOpenTable] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [monthOption, setMonthOption] = useState('');
  const [paymentList, setPaymentList] = useState([]);

  const [agency, setAgency] = useState('');
  const [lifeLong, setLifeLong] = useState('');
  const [agencyMonth, setAgencyMonth] = useState('');
  const [agencyYear, setAgencyYear] = useState('');
  const [selectedAllMonth, setSelectedAllMonth] = useState(false);

  const [openHistoric, setOpenHistoric] = useState(false);
  const [filterYear, setFilterYear] = useState(moment().format('YYYY'));
  const [yearOptions, setYearOptions] = useState([]);
  const [allCommissining, setAllCommissioning] = useState([]);
  const [commissioningList, setCommissioningList] = useState([]);
  const [editData, setEditData] = useState('');

  const { handleSubmit, getValues } = useForm({
    defaultValues: {
      contrato_id: data.length > 0 ? data[0]['id'] : '',
      estipulante_id: data.length > 0 ? data[0]['estipulante_id'] : ''
    }
  });

  const toggleOpenTable = () => {
    setOpenTable((prevState) => !prevState);
  };

  const toggleOpenHistoric = () => {
    setOpenHistoric((prevState) => !prevState);
    getAllCommissioningByYear();
  };

  const getPaymentList = () => {
    setPaymentList((prevState) => [
      ...prevState,
      {
        agenciamento_valor: agency,
        mes: agencyMonth,
        ano: agencyYear
      }
    ]);
  };

  const removeAgencyInPaymentList = (index) => {
    const paymentFiltered = paymentList?.filter(
      (_payment, paymentIndex) => paymentIndex !== index
    );
    setPaymentList(paymentFiltered);
  };

  const getComissionamento = async () => {
    const { contrato_id } = getValues();

    await services.insuranceCompanies
      .getCommissionContractId(contrato_id)
      .then((resp) => {
        if (resp.data) {
          setComissionamentos([resp.data]);
        } else {
          setComissionamentos([]);
        }
      });
  };

  const getAllCommissioningByYear = async () => {
    const { contrato_id } = getValues();
    const response = await services.insuranceCompanies.getCommissionByYear(
      contrato_id
    );
    if (response.data) {
      setAllCommissioning(response.data);
      const values = response.data.map((item) => item.ano);
      const uniqueValues = [...new Set(values)];
      const options = uniqueValues.map((item) => ({
        value: item,
        option: item
      }));
      setYearOptions(options);
    } else {
      setYearOptions([]);
    }
  };

  const updateCommissioningData = async (comissionamento_id, type, data) => {
    const body = {
      [type]: data
    };
    const response = await services.insuranceCompanies.updateCommissioningData(
      comissionamento_id,
      body
    );
    if (response.status === 200) {
      getAllCommissioningByYear();
    }
  };

  const onSubmit = async () => {
    const { estipulante_id, contrato_id } = getValues();
    const body = {
      estipulante_id,
      contrato_id,
      agenciamento:
        paymentList.length > 0 ? paymentList : agency ? agency : null,
      vitalicio: lifeLong ? lifeLong : null,
      mes: agencyMonth,
      ano: agencyYear,
      aplicar_todos_meses: selectedAllMonth,
      tipo_comissionamento: paymentType
    };

    const response = await services.insuranceCompanies.createCommissioning(
      body
    );

    if (response.status === 201) {
      setOpenTable(false);
      setPaymentType('');
      setMonthOption('');
      setPaymentList([]);
      setAgency('');
      setLifeLong('');
      setAgencyMonth('');
      setAgencyYear('');
      setSelectedAllMonth(false);
      getComissionamento();
    }
  };

  useEffect(() => {
    if (allCommissining.length > 0) {
      const filteredCommissioning = allCommissining.filter(
        (item) => item.ano === filterYear
      );
      setCommissioningList(filteredCommissioning);
    }
  }, [allCommissining, filterYear]);

  // UseEffect do primeiro carregamento de tela
  useEffect(() => {
    getComissionamento();
  }, []);

  return (
    <div className="commissioning">
      <TopBar title={'Remuneração'} />
      <div className="commissioning-content">
        <div
          className={
            openTable
              ? 'commissioning-content--form-open'
              : 'commissioning-content--form-close'
          }
        >
          {
            // Esse map só renderiza uma vez, foi usado como base o componente Reajuste que renderizava vários de uma vez
            data &&
              data.map((contrato) => {
                return (
                  <div className="commissioning-table">
                    <div className="commissioning-table-header">
                      <div className="commissioning-table-header__theader">
                        <p className="commissioning-table-header__th">
                          Agenciamento
                        </p>
                        <p className="commissioning-table-header__th">
                          Vitalício
                        </p>
                        <p className="commissioning-table-header__th">Ano</p>
                      </div>
                    </div>
                    <div className="commissioning-table-body">
                      {comissionamentos.map((comissao, index) => {
                        return (
                          <div className="commissioning-table-body__tbody">
                            <div className="commissioning-table-body__td">
                              <p>
                                {comissao['agenciamento']
                                  ? `${comissao['agenciamento']?.replace(
                                      '.',
                                      ','
                                    )} %`
                                  : ''}
                              </p>
                              <p>
                                {comissao['vitalicio']
                                  ? `${comissao['vitalicio']?.replace(
                                      '.',
                                      ','
                                    )} %`
                                  : ''}
                              </p>
                              <p>{comissao['ano'] ? comissao['ano'] : ''}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
          }
        </div>

        <div className="commissioning-form">
          {comissionamentos?.length > 0 ? (
            <div className="commissioning-form__group-bottons">
              <Button
                variant="transparent"
                size="medium"
                onClick={toggleOpenTable}
              >
                Atualizar Remuneração
              </Button>
              <Button
                variant="transparent"
                size="medium"
                onClick={toggleOpenHistoric}
              >
                Ver/ Editar Remuneração
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="transparent"
                size="medium"
                onClick={toggleOpenTable}
              >
                <BiPlus
                  className={
                    openTable
                      ? 'commissioning-form--close'
                      : 'commissioning-form--open'
                  }
                  size={16}
                />
                Remuneração
              </Button>
            </div>
          )}

          <form
            className={
              openTable
                ? 'commissioning-form--visible'
                : 'commissioning-form--hidden'
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="commissioning-form-content">
              <div className="commissioning-form-content-radio">
                <div className="commissioning-form-content-radio__radio">
                  <Radio
                    size="small"
                    onClick={() => setPaymentType('agenciamento')}
                    checked={paymentType === 'agenciamento'}
                  />
                  <label className="commissioning-form-content-radio__label">
                    Agenciamento
                  </label>
                </div>
                <div className="commissioning-form-content-radio__radio">
                  <Radio
                    size="small"
                    onClick={() => setPaymentType('vitalicio')}
                    checked={paymentType === 'vitalicio'}
                  />
                  <label className="commissioning-form-content-radio__label">
                    Vitalício
                  </label>
                </div>
              </div>
              {paymentType === 'agenciamento' ? (
                <div>
                  <div className="commissioning-form-content-radio">
                    <div className="commissioning-form-content-radio__radio">
                      <Radio
                        size="small"
                        onClick={() => setMonthOption('one')}
                        checked={monthOption === 'one'}
                      />
                      <label className="commissioning-form-content-radio__label">
                        Aplicar em 1 mês?
                      </label>
                    </div>
                    <div className="commissioning-form-content-radio__radio">
                      <Radio
                        size="small"
                        onClick={() => setMonthOption('more')}
                        checked={monthOption === 'more'}
                      />
                      <label className="commissioning-form-content-radio__label">
                        Aplicar em mais meses?
                      </label>
                    </div>
                  </div>
                  {monthOption === 'one' && (
                    <div className="commissioning-form-agency-container">
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Agenciamento (%)
                        </label>
                        <input
                          className="commissioning-form-agency__input"
                          type="text"
                          onChange={(e) => setAgency(e.target.value)}
                        />
                      </div>
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Mês
                        </label>
                        <select
                          className="commissioning-form-agency__select"
                          value={agencyMonth}
                          onChange={(e) => setAgencyMonth(e.target.value)}
                        >
                          <option value="">Selecione...</option>
                          {arrObjectMonth.map((month) => (
                            <option value={month.value}>{month.option}</option>
                          ))}
                        </select>
                      </div>
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Ano
                        </label>
                        <input
                          className="commissioning-form-agency__input"
                          type="text"
                          onChange={(e) => setAgencyYear(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {monthOption === 'more' && (
                    <div>
                      <div className="commissioning-form-agency-container--more">
                        <div className="commissioning-form-agency">
                          <label className="commissioning-form-agency__label">
                            Agenciamento (%)
                          </label>
                          <input
                            className="commissioning-form-agency__input"
                            type="text"
                            onChange={(e) => setAgency(e.target.value)}
                          />
                        </div>
                        <div className="commissioning-form-agency">
                          <label className="commissioning-form-agency__label">
                            Mês
                          </label>
                          <select
                            className="commissioning-form-agency__select"
                            value={agencyMonth}
                            onChange={(e) => setAgencyMonth(e.target.value)}
                          >
                            <option value="">Selecione...</option>
                            {arrObjectMonth.map((month) => (
                              <option value={month.value}>
                                {month.option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="commissioning-form-agency">
                          <label className="commissioning-form-agency__label">
                            Ano
                          </label>
                          <input
                            className="commissioning-form-agency__input"
                            type="text"
                            onChange={(e) => setAgencyYear(e.target.value)}
                          />
                        </div>
                        <div className="commissioning-form-agency__button-container">
                          <Button
                            type="button"
                            variant="primary"
                            size="small"
                            onClick={() => getPaymentList()}
                          >
                            Adicionar
                          </Button>
                        </div>
                      </div>
                      {paymentList?.length > 0 && (
                        <div className="commissioning-form-agency-table-container">
                          <table className="commissioning-form-agency-table">
                            <thead>
                              <tr className="commissioning-form-agency-table__theader-tr">
                                <th className="commissioning-form-agency-table__theader-th">
                                  Agenciamento (%)
                                </th>
                                <th className="commissioning-form-agency-table__theader-th">
                                  Mês
                                </th>
                                <th className="commissioning-form-agency-table__theader-th">
                                  Ano
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList?.map((item, index) => (
                                <tr className="commissioning-form-agency-table__tbody-tr">
                                  <td className="commissioning-form-agency-table__tbody-td">
                                    {item.agenciamento_valor}
                                  </td>
                                  <td className="commissioning-form-agency-table__tbody-td">
                                    {monthsObject[item.mes]}
                                  </td>
                                  <td className="commissioning-form-agency-table__tbody-td">
                                    {item.ano}
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      variant="remove"
                                      size="small"
                                      onClick={() =>
                                        removeAgencyInPaymentList(index)
                                      }
                                    >
                                      X
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                paymentType === 'vitalicio' && (
                  <div>
                    <div className="commissioning-form-agency-container">
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Vitalício (%)
                        </label>
                        <input
                          className="commissioning-form-agency__input"
                          type="text"
                          placeholder="Vitalício"
                          onChange={(e) => setLifeLong(e.target.value)}
                        />
                      </div>
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Mês
                        </label>
                        <select
                          className="commissioning-form-agency__select"
                          value={agencyMonth}
                          onChange={(e) => setAgencyMonth(e.target.value)}
                        >
                          <option value="">Selecione...</option>
                          {arrObjectMonth.map((month) => (
                            <option value={month.value}>{month.option}</option>
                          ))}
                        </select>
                      </div>
                      <div className="commissioning-form-agency">
                        <label className="commissioning-form-agency__label">
                          Ano
                        </label>
                        <input
                          className="commissioning-form-agency__input"
                          type="text"
                          onChange={(e) => setAgencyYear(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="commissioning-form-agency__radio">
                      <Radio
                        size="small"
                        onClick={() =>
                          setSelectedAllMonth((prevState) => !prevState)
                        }
                        checked={selectedAllMonth}
                      />
                      <label className="commissioning-form-agency__label">
                        Aplicar este vitalício para todos os meses
                      </label>
                    </div>
                  </div>
                )
              )}
              <div className="commissioning-form-agency__button-container">
                <Button type="submit" variant="save" size="small">
                  Salvar
                </Button>
              </div>
            </div>
          </form>
        </div>
        {openHistoric && (
          <ComissionamentoHistorico
            yearOptions={yearOptions}
            filterYear={filterYear}
            setFilterYear={setFilterYear}
            commissioningList={commissioningList}
            editData={editData}
            setEditData={setEditData}
            updateCommissioningData={updateCommissioningData}
          />
        )}
      </div>
      <BottomBar />
    </div>
  );
};

export default ComissionamentoInner;
