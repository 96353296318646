import Button from 'components/DS/Button';
import { useState } from 'react';
import { VscCheck, VscChromeClose } from 'react-icons/vsc';

const ComissionamentoHistorico = ({
  yearOptions,
  filterYear,
  setFilterYear,
  commissioningList,
  editData,
  setEditData,
  updateCommissioningData
}) => {
  const [openEditData, setOpenEditData] = useState({ id: 0, data: '' });

  const handleChangeFilterYear = (e) => {
    setFilterYear(e.target.value);
  };

  const editDataChangeHandler = (e) => {
    setEditData(e.target.value);
  };

  return (
    <div className="commissioning-historic">
      <div className="commissioning-historic__header">
        <div className="commissioning-historic__info">
          <span className="commissioning-historic__info--title">
            Tabela Completa de Remuneração
          </span>
          <span className="commissioning-historic__info--subtitle">
            Para editar clique nos valores de agenciamento ou vitalício
          </span>
        </div>
        <div className="commissioning-historic-filter">
          <label className="commissioning-historic-filter__label">
            Filtrar por ano:
          </label>
          <select
            className="commissioning-historic-filter__select"
            value={filterYear}
            onChange={handleChangeFilterYear}
          >
            <option value="">Selecione</option>
            {yearOptions.map((item) => (
              <option value={item.value} key={item.value}>
                {item.option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <table className="commissioning-historic-table">
          <thead>
            <tr className="commissioning-historic-table__theader-tr">
              <th>Agenciamento</th>
              <th>Vitalício</th>
              <th>Mês</th>
              <th>Ano</th>
            </tr>
          </thead>
          <tbody>
            {commissioningList &&
              commissioningList.map((item) => (
                <tr
                  className="commissioning-historic-table__tbody-tr"
                  key={item.id}
                >
                  {openEditData.id === item.id &&
                  openEditData.data === 'Agenciamento' ? (
                    <td className="commissioning-historic-table__edit-data">
                      <input
                        className="commissioning-historic-table__input"
                        type="text"
                        valeu={editData}
                        onChange={editDataChangeHandler}
                      />
                      <Button
                        variant="transparent"
                        size="small"
                        onClick={() => {
                          updateCommissioningData(
                            item.id,
                            'agenciamento',
                            editData
                          );
                          setOpenEditData({ id: 0, data: '' });
                        }}
                      >
                        <VscCheck
                          className="commissioning-historic-table__icon--check"
                          size={14}
                        />
                      </Button>
                      <Button
                        variant="transparent"
                        size="small"
                        onClick={() => {
                          setEditData('');
                          setOpenEditData({ id: 0, data: '' });
                        }}
                      >
                        <VscChromeClose
                          className="commissioning-historic-table__icon--cancel"
                          size={14}
                        />
                      </Button>
                    </td>
                  ) : (
                    <td
                      onClick={() =>
                        setOpenEditData({ id: item.id, data: 'Agenciamento' })
                      }
                    >{`${item.agenciamento ? item.agenciamento : '0'} %`}</td>
                  )}
                  {openEditData.id === item.id &&
                  openEditData.data === 'Vitalicio' ? (
                    <td className="commissioning-historic-table__edit-data">
                      <input
                        className="commissioning-historic-table__input"
                        type="text"
                        valeu={editData}
                        onChange={editDataChangeHandler}
                      />
                      <Button
                        variant="transparent"
                        size="small"
                        onClick={() => {
                          updateCommissioningData(
                            item.id,
                            'vitalicio',
                            editData
                          );
                          setOpenEditData({ id: 0, data: '' });
                        }}
                      >
                        <VscCheck
                          className="commissioning-historic-table__icon--check"
                          size={14}
                        />
                      </Button>
                      <Button
                        variant="transparent"
                        size="small"
                        onClick={() => {
                          setEditData('');
                          setOpenEditData({ id: 0, data: '' });
                        }}
                      >
                        <VscChromeClose
                          className="commissioning-historic-table__icon--cancel"
                          size={14}
                        />
                      </Button>
                    </td>
                  ) : (
                    <td
                      onClick={() =>
                        setOpenEditData({ id: item.id, data: 'Vitalicio' })
                      }
                    >{`${item.vitalicio ? item.vitalicio : '0'} %`}</td>
                  )}
                  <td>{item.mes}</td>
                  <td>{item.ano}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComissionamentoHistorico;
